import React, { useEffect, useState } from 'react'
import moment from 'moment'
import axios from 'axios'
import hearts from '../../Assets/bg-hearts.png'
import { useParams } from 'react-router-dom'
import { WeddingSchedule } from '../../Constants';
import './PublicWedding.css'

export default function PublicWedding() {

    const { friendlyURL } = useParams()

    const [events, setEvents] = useState([])
    const [date, setDate] = useState("")
    const [client, setClient] = useState("")
    const [spouse, setSpouse] = useState("")

    useEffect(() => {
        getWedding()
    }, [friendlyURL])

    const createTitle = () => {
        if(client && !spouse){
            return `Wedding of ${client.split(" ")[0]}`
        } else if(client && spouse){
            return `Wedding of ${client.split(" ")[0]} & ${spouse.split(" ")[0]}`
        } else {
            return "Wedding Schedule"
        }
    }

    const getWedding = () => {
        axios.post(`${process.env.REACT_APP_API}/weddings/get-wedding-by-url`, {friendlyURL})
        .then((res) => {
            const filteredEvents = res.data.events.filter(event => event.visible);
            setEvents(filteredEvents);
            setDate(res.data.weddingDate)
            setClient(res.data.clientName)
            setSpouse(res.data.spouseName)
        })
        .catch((e) => {
            console.log(e)
            //Potentially return unauthorized if set 'private' by client
        })
    }

    function timeToMinutes(time) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    }

    // Function to sort events by startTime
    function sortEvents(events) {
        return events.sort((a, b) => {
            const timeA = timeToMinutes(a.startTime);
            const timeB = timeToMinutes(b.startTime);

            // Custom sorting logic: if the time is before 05:00, add 1440 minutes (24 hours) to push it to the end. This is to ensure any weddings going past midnight are accounted for
            const adjustedTimeA = timeA < 300 ? timeA + 1440 : timeA;
            const adjustedTimeB = timeB < 300 ? timeB + 1440 : timeB;

            return adjustedTimeA - adjustedTimeB;
        });
    }

  return (
    <div style={{backgroundColor: 'rgba(252, 224, 224, 0.32)', width: '100vw', height: '100vh', padding: 20}}>
        <img id='bg-hearts' src={hearts} style={{zIndex: 1}}/> 

        <div style={{width: 1000, maxWidth: '100%', margin: '0 auto', textAlign:'center', position:'relative', zIndex: 2 }}>
            <h1 style={{fontWeight: 900, marginBottom : 10, color:'#313131'}}>{createTitle()}</h1>
            <h2 style={{margin: 0, fontSize: 18, fontWeight: 900, color: '#ecbdba', marginBottom: 20}}>{moment(date).format('Do, MMMM YYYY')}</h2>

            {events?.map((item, i) => {
                        let lastIndex = events?.length -1
                        let eventInfo = WeddingSchedule.find(event => event.id == item.eventId)
                        return (
                            <div key={i} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height: 120}}>
                                <div className='public-timeline-container'>
                                    <div className={i === 0 ? "timeline-no-line" : 'public-timeline-line'}/>
                                    <div className='public-timeline-dot'/>
                                    <div className={i === lastIndex ? "timeline-no-line" : 'public-timeline-line'}/>
                                </div>

                                <div key={i} className='public-schedule-item-container'>
                                    <img style={{height: 55, marginRight: 30, marginLeft: 10}} src={eventInfo?.icon}/>
                                    <div style={{display: 'flex', flexDirection:'column', width: '60%'}}>
                                        <p style={{fontWeight: 700, textAlign:'left', margin: 0}}>{item.startTime} {item.endTime && `- ${item.endTime}`}</p>
                                        <p style={{fontWeight: 700, textAlign:'left', margin: '5px 0'}}>{eventInfo?.name}</p>
                                    </div>
                                    <div className={!item.visible ? 'disabled-overlay' :""}/>
                                </div>
                            </div>
                        )
            })}
        </div>

    </div>
  )
}
